import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Box, LinearProgress,
} from '@mui/material';
import LoginLogoImg from '../../assets/img/loginLogoImg.png';
import LoginLogoImgWebp from '../../assets/img/loginLogoImg.webp';
import LoginLogoMobImg from '../../assets/img/loginLogoMobImg.png';
import LoginLogoMobImgWebp from '../../assets/img/loginLogoMobImg.webp';

const LoadingScreen = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box display="flex" height={isMobile ? '100dvh' : '100vh'} width="100%" sx={{ backgroundColor: 'background.paper' }}>
      <Box display="flex" justifyContent="center" alignItems="center" height="100%" flexGrow={1}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <img
            src={isMobile ? LoginLogoMobImg : LoginLogoImg}
            srcSet={isMobile ? LoginLogoMobImgWebp : LoginLogoImgWebp}
            alt="logo"
            loading="lazy"
            width={isMobile ? 197 : 266}
            height={isMobile ? 59 : 80}
          />
          <Box sx={{ width: isMobile ? 250 : 480, marginTop: isMobile ? '35px' : '55px' }}>
            <LinearProgress sx={{ borderRadius: '2px' }} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LoadingScreen;
